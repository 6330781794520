@import 'fonts.css';
@import 'css-reset.css';


body {
  width: 100%;
  background: #0F0F0F;
  font-family: 'AeonikFono';
  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none !important;
  color: white;
}