@font-face {
  font-family: 'AeonikFono';
  font-style: normal;
  font-weight: 300;
  src:
    local('AeonikFono'),
    url('./fonts/AeonikFono/AeonikFono-Light.otf') format('truetype'),
    url('./fonts/AeonikFono/AeonikFono-Light.otf') format('truetype');

}

@font-face {
  font-family: 'AeonikFono';
  font-style: normal;
  font-weight: 400;
  src:
    local('AeonikFono'),
    url('./fonts/AeonikFono/AeonikFono-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'AeonikFono';
  font-style: normal;
  font-weight: 500;
  src:
    local('AeonikFono'),
    url('./fonts/AeonikFono/AeonikFono-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'AeonikFono';
  font-style: normal;
  font-weight: 700;
  src:
    local('AeonikFono'),
    url('./fonts/AeonikFono/AeonikFono-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'AeonikFono';
  font-style: normal;
  font-weight: 900;
  src:
    local('AeonikFono'),
    url('./fonts/AeonikFono/AeonikFono-Black.otf') format('truetype');
}